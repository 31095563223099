import React from 'react';
import ArticleLayout from './src/components/ArticleLayout';
import Layout from './src/components/Layout';
import { OrderProvider } from './src/components/OrderContext';

const articles = [
  '/liberando-el-potencial/' ,
  '/coaching/',
  '/consultoria/',
  '/auditoria/',
];

export function wrapPageElement({ element, props }) {
  console.log(element.key);

  return (
    <>
      {articles && articles.includes(element.key) ? (
        <ArticleLayout className={element.key.substring(1, element.key.length-1)} {...props}>{element}</ArticleLayout>
      ) : (
        <Layout {...props}>{element}</Layout>
      )}
    </>
  );
}

export function wrapRootElement({ element }) {
  return <OrderProvider>{element}</OrderProvider>;
}
