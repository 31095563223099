import React from 'react';
import { Link } from 'gatsby';
import SocialLinks from './SocialLinks';

export default function Footer({ children }) {

  return (
    <footer>
      <SocialLinks className="center courier">
      </SocialLinks>
      <h5 className="center"></h5> 
    </footer>
  );
}
