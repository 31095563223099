import React from 'react';
import styled from 'styled-components';

const MiniStyles = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 10px;
  max-width: 80%;
  margin: 0.5rem auto;

  .link-container {
    grid-column: 1 / span 5;
    grid-row: 1;
    a {
      text-decoration: none;
      img {
        max-width: 8rem;
        display: block;
        margin: 0 auto;
      }
    }
  }

  .link-border-container {
    margin: 2px auto;
    max-width: 70%;
    width: 70%;
    max-height: 3px;
    height: 3px;

    grid-column: 3 / span 1;
    grid-row: 2;

    &.white {
      background-color: var(--white);
    }
    &.blue {
      background-color: var(--blue);
    }
    &.yellow {
      background-color: var(--yellow);
    }
    &.black {
      background-color: var(--black);
  }
  }

  @media (max-width: 800px) {
    .link-container {
    }
  }
`;

export default function MiniLink({
  children,
  color = 'white',
  borderColor = 'white',
  customWidth = {},
  customWidthbase = {}
}) {
  return (
    <MiniStyles>
      <div className={`link-container ${color}`} style={customWidthbase}>{children}</div>
      <div
        className={`link-border-container ${borderColor}`}
        style={customWidth}
      ></div>
    </MiniStyles>
  );
}
