import React, { useRef } from 'react';

import styled from 'styled-components';
import 'normalize.css';
import Logo from './Logo';
import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import stripes from '../assets/images/dash.svg';

import bg from '../assets/images/photo-1519681393784-d120267933ba.jpg';

const SiteBorderStyles = styled.div`

    background-image: url(${bg});
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: screen;
    background-color: #4d4b4b; /*#9E9E9E;*/
    position: relative;
    z-index: 1;
    margin: 12rem auto 0 auto;
    margin-top: clamp(1rem, 2vw, 2rem);

  padding: 5px;
  padding: 0 clamp(5px, 1vw, 25px);
  /*box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);*/

  @media (max-width: 1100px) {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 100%;
  }
`;

const ContentStyles = styled.div`
  background: transparent;
  padding: 2rem;

  @media (max-width: 1100px) {
    padding: 0.5rem;
  }
`;

export default function Layout({ children }) {
  const ref = useRef();
  return (
    <>
      <GlobalStyles />
      <Typography />

      <Logo logoTrack={ref} />
      
      <Nav active={children.key} logoTrack={ref} />
      <SiteBorderStyles>
        <ContentStyles>
          {children}
          <Footer />
        </ContentStyles>
      </SiteBorderStyles>

    </>
  );
}
