import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
//import logo from '../../static/logo.svg';

import SocialLinks from './SocialLinks';
import MiniLink from './MiniLink';

const LogoStyles = styled.div`
  /* This value controls the entire size of the logo*/
  font-size: 6px;
  font-size: clamp(1px, 0.65vw, 8px);
  /*width: 100%;*/
  height: 16rem;
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);*/
  margin: 0;
  --borderSize: 1em;
  background: white; /*url(${stripes});*/
  /*background-size: 150em;*/
  /*border: var(--borderSize) solid white;*/
  display: flex;
  justify-content: center;
  align-content: center;

  margin: 0 auto;
  text-align: center;

  &.small {
    font-size: inherit;
    width: 8rem;
    height: 8rem;
    border: none;

    .inner {
      display: block;
      margin: 0;
      background: none;
    }

    h2, .social-head {
      display: none;
    }
  }

  .inner {
    margin: var(--borderSize);
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    align-content: center;
  }

  .est {
    font-size: 1.5em;
    align-self: center;
  }

  h1 {
    /*display: grid;
    grid-template-rows: 8fr 2fr;
    align-items: center;*/
    margin: 0;
    /*grid-row: 2 / span 2;*/
    grid-gap: 0;

    a {
      text-decoration: none;
    }
    
  }

  h2 {
    font-size: 2rem;
    
  }

  .social-head {
    max-width: 25rem;
    margin: 0 auto;
     a {
       svg {
        height: 1.2rem;
        width: 1.2rem;
       }
     }
  }


  @media (max-width: 600px) {
      &.small {
      width: 6rem;
      height: 6rem;
    }
  }
`;

export default function Logo({ logoTrack, className = '' }) {
  const customWidth = {width: '100%', maxWidth: '100%'};
  return (
    <LogoStyles className={className}>
      <div className="inner" ref={logoTrack}>
        <h1 className="head">
            <Link to="/">
            Fernando Sandoval Arenas
            </Link>
        </h1>
        
          <h2>
            <span className="">Coaching & Consultoría</span>
          </h2>
          
        <div className="social-head">
          <SocialLinks showDesc={false} />
        </div>
        
      </div>
    </LogoStyles>
  );
}
