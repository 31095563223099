import React, { useRef } from 'react';

import styled from 'styled-components';
import 'normalize.css';
import Logo from './Logo';
import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import stripes from '../assets/images/dash.svg';


import bg from '../assets/images/photo-1519681393784-d120267933ba.jpg';
import bgCoaching from '../assets/images/consultoria-coaching.jpg';
import bgPotencial from '../assets/images/BLOG_1.jpg';


const SiteBorderStyles = styled.div`


    background-image: url(${bg});
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: screen;
    background-color: #1f1e1e; /*#9E9E9E;*/


    position: relative;
    z-index: 1;
    margin: 0 auto;

    a , span{
      color: var(--white);
    }

    &.coaching {
      background-image: url(${bgCoaching});
    }

    &.liberando-el-potencial {
      background-image: url(${bgPotencial});
    }

    @media (max-width: 1100px) {
        margin-left: 0;
        margin-right: 0;
    }
`;

const ContentStyles = styled.div`
    margin-top: 1rem;
    /*margin-top: 10%;*/

  @media (max-width: 1100px) {
    padding: 0.5rem;
  }
`;

export default function ArticleLayout({ children, className = '' }) {
  const ref = useRef();
  return (
    <>
      <GlobalStyles />
      <Typography />

      <SiteBorderStyles className={className}>
      <Logo logoTrack={ref} className="logo"/>

      <Nav active={children.key} logoTrack={ref} customStyle={{margin: 0}} className="logo" />
        <ContentStyles>
          {children}
          <Footer />
        </ContentStyles>
      </SiteBorderStyles>

    </>
  );
}
