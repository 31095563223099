import { useState, useEffect } from 'react';

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  if (typeof window !== 'undefined') {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    useEffect(() => {
      //console.log(ref);
      try {
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
      } catch (e) {
        console.log(e);
      }

      return () => {
        observer.disconnect();
      };
    }, []);
  }

  return isIntersecting;
}
