import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {FaRegEnvelope, FaLinkedinIn, FaTwitter} from 'react-icons/fa';

const SocialStyles = styled.div`
  display: grid;
  --columns: 3;
  grid-template-columns: repeat(var(--columns), 1fr);
  align-items: center;
  &.jumbo {
    margin: 1rem 0;
    height: 10vh;
    background: rgba(53, 53, 53, 0.1);
    backdrop-filter: blur(62px);
    color: var(--white);
      a {
        color: var(--white);
        &:hover {
          color: var(--yellow);
        }
      }
  }
  a {
    margin-left: 1rem;
    text-decoration: none;
  }
  
  @media (max-width: 800px) {
    --columns: 3;
    grid-gap: 1rem;
    &.jumbo a, a {
      margin: 0;
      margin-top: 1rem;
      span {
        display: none;
      }

    }
  }
`;

export default function SocialLinks({ showDesc = true }) {
  const { site } = useStaticQuery(graphql`
  query {
      site {
        siteMetadata {
          title
          mail
          linkedIn
          twitter
        }
      }
    }
`);

const {  mail, linkedIn, twitter } = site.siteMetadata;
  return (
      <SocialStyles className={showDesc ? "center courier jumbo" : "center courier"}>
        <a href={`mailto:${mail}`} alt={mail}> <span>{showDesc ? 'Envíame un correo' : ''}</span> <FaRegEnvelope /></a>
        <a href={`${linkedIn}`}> <span>{showDesc ? 'Sígueme en linkedIn': ''}</span> <FaLinkedinIn /></a>
        <a href={`https://twitter.com/${twitter}`}> <span>{showDesc ? `Sígueme en twitter ${twitter}` : ''} </span> <FaTwitter /></a>
      </SocialStyles>
  );
}
