import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { HiMenu } from 'react-icons/hi';

import useOnScreen from '../utils/useOnScreen';

import Logo from './Logo';
import Menu from './Menu';

const NavStyles = styled.nav`
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  padding: 0 0.5rem 1rem 0.5rem;

  /*box-shadow: 7px 7px 5px 3px rgba(0, 0, 0, 0.044);*/

  &:hover {
    z-index: 1000;
  }
  .logo {
    transform: translateY(-25%);
  }

  ul {
    margin: 0;
    padding: 0.25rem 0;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(6, auto); /*1fr 1fr auto 1fr 1fr;*/
    grid-gap: 0.5rem;
    align-items: center;
    &.sticked {
      grid-template-columns: repeat(7, auto); /*1fr 1fr auto 1fr 1fr;*/
      box-shadow: 7px 7px 5px 3px rgba(0, 0, 0, 0.044);
      background-color: #fff;
        li {
          a {
          color: var(--blue);
              }
          span {
                color: var(--blue);

              }
              &.active {
                a {
                  color: var(--yellow);
                  text-decoration: underline;
                }
                span {
                  color: var(--yellow);
                  text-decoration: none;
                }
              }
          }
        
      
      svg {
          display: none;
        }
    }
  }
  li {
    /* --rotate: -1deg;
    transform: rotate(var(--rotate));*/
    order: 1;

    &.logo-holder {
            grid-column: 1;
            grid-row: 1;
            display: block;
          }

    &.active {
      a {
        color: var(--yellow);
        text-decoration: underline;
      }
      span {
        color: var(--yellow);
        text-decoration: none;
      }
    }
  }
  a {
    font-size: 2rem;
    text-decoration: none;
    color: var(--black);
    display: block;
    &:hover {
      color: var(--blue);
      text-decoration: underline;
    }

    @media (max-width: 800px) {
      font-size: 1.5rem;
    }

    &[aria-current='page'] {
      color: var(--black);
    }
  }

  @media (max-width: 600px) {
    --columns: 4;
    margin-bottom: 2rem;
    border-bottom: 2px solid var(--grey);
    padding-bottom: 2rem;

    ul {
      grid-template-rows: auto auto;
      grid-template-columns: repeat(var(--columns), 1fr);
      justify-items: center;

      &.sticked {
        grid-template-columns: repeat(4, auto);
        padding-top: 2rem;
        svg {
          display: block;
        }

        li {
          display: none;
            &.logo-holder {
            grid-column: 2 / span 2;
            grid-row: 1 / span 2;
            display: block;
          }
        }
      }

      li {
        a {
          font-size: 1.2rem;
        }
        &.sticked, .sticked {
          display: none;
        }

        &.logo-holder {
          grid-column: 1;
          grid-row: 1 / span 2;
        }
      }
    }
    .logo-item {
      order: 0;
      grid-column: 1 / -1;
    }
    .logo {
      transform: none;
    }
  }
  @media (max-width: 500px) {
    --columns: 2;
    ul {
      &.sticked {
        grid-template-columns: repeat(4, auto);
        padding-top: 2rem;
      }
    }
  }
`;

export const sections = {
  INDEX: '/',
  COACHING: '/coaching/',
  CONSULTORIA: '/consultoria/',
  AUDITORIA: '/auditoria/',
  ALIANZAS: '/alianzas/',

  NOSOTROS: '/nosotros/',
  ORDEN: '/orden/',
};

export default function Nav({
  active,
  logoTrack,
  className = '',
  customStyle,
}) {
  const isVisible = useOnScreen(logoTrack);
  const [menu, isMenu] = useState(false);

  return (
    <NavStyles style={customStyle} className={className}>

      <ul className={isVisible ? '' : 'sticked'}>
        {!isVisible && <HiMenu onClick={() => isMenu(!menu)} />}
        {!isVisible && (
          <li className="logo-holder">
            <Logo className="small" />
          </li>
        )}
        <li
          className={
            active === sections.INDEX ? 'active' : isVisible ? '' : 'sticked'
          }
        >
          <Link to="/" className={isVisible ? '' : 'sticked'}>
            <b>INICIO</b>
          </Link>
        </li>

        <li className={active === sections.COACHING ? 'active' : ''}>
          <Link to={sections.COACHING}><b>COACHING</b></Link>
        </li>
        <li className={active === sections.CONSULTORIA ? 'active' : ''}>
          <Link to={sections.CONSULTORIA}><b>CONSULTORÍA</b></Link>
        </li>
        <li className={active === sections.AUDITORIA ? 'active' : ''}>
          <Link to={sections.AUDITORIA}><b>AUDITORÍA</b></Link>
        </li>
        <li className={active === sections.ALIANZAS ? 'active' : ''}>
          <Link to={sections.ALIANZAS}><b>ALIANZAS</b></Link>
        </li>

        <li>
          <a href="http://fernandosandovalarenas.blogspot.com/" target="blank">
            BLOG
          </a>
        </li>

      </ul>
      {!isVisible && <Menu active={menu} showModal={isMenu}>
        <div className="mobileMenuContainer">
          <div className={active === sections.COACHING ? 'active' : ''}>
          <Link to="/" >
            <b>INICIO</b>
          </Link>
        </div>

        <div className={active === sections.COACHING ? 'active' : ''}>
          <Link to={sections.COACHING}><b>COACHING</b></Link>
        </div>
        <div className={active === sections.CONSULTORIA ? 'active' : ''}>
          <Link to={sections.CONSULTORIA}><b>CONSULTORÍA</b></Link>
        </div>
        <div className={active === sections.AUDITORIA ? 'active' : ''}>
          <Link to={sections.AUDITORIA}><b>AUDITORÍA</b></Link>
        </div>
        <div className={active === sections.ALIANZAS ? 'active' : ''}>
          <Link to={sections.ALIANZAS}><b>ALIANZAS</b></Link>
        </div>

        <div>
          <a href="http://fernandosandovalarenas.blogspot.com/" target="blank">
            BLOG
          </a>
        </div>


        </div>
      </Menu>
      }
    </NavStyles>
  );
}
