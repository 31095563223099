import { createGlobalStyle } from 'styled-components';
//import bg from '../assets/images/bg.svg';
import stripes from '../assets/images/stripes.svg';

import bg from '../assets/images/photo-1519681393784-d120267933ba.jpg';

const GlobalStyles = createGlobalStyle`
  :root {
    --blue: #1B75BC;/* FF4949;*/
    --black: #0B4464; /*#2E2E2E; */
    --yellow: #FBB863;/*ffc600;*/
    --white: #fff;
    --grey: #efefef;
    --green: #356666;
    --lightyellow: #eaedae;
    --lightgreen: #FBB863 /*#acd098*/;
    --eblue: #df3428;
  }
  html {
    max-width: 100%;
    font-size: 10px;

  }

  body {
    font-size: 2rem;
    max-width: 100%;
    width: 100vw;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button, .button {
    background: var(--blue);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    text-decoration: none;
    line-height: 1.15;
    height: 5rem;
    &:hover {
      --cast: 4px;
    background-color: var(--lightgreen);
    color: var(--black)
    }
    &.add-btn {
      margin: 1rem 0;
      padding: 1rem;
      grid-column: 2 / span 1;
      width: 11.5rem;
      height: 4.5rem;
      background-color: var(--lightgreen);
      color: var(--black);
      &:hover {
        background-color: var(--green);
        color: #fff;
      }
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--blue) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--blue) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .logo {
    background-color:transparent;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }
  .error {
    color: var(--eblue);
  }

  .white {
    color: var(--white);
  }

  .blue {
    color: var(--blue);
  }

  .yellow {
    color: var(--yellow);
  }

  .black {
    color: var(--black);
  }

`;

export default GlobalStyles;
