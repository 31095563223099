// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alianzas-js": () => import("./../../../src/pages/alianzas.js" /* webpackChunkName: "component---src-pages-alianzas-js" */),
  "component---src-pages-auditoria-js": () => import("./../../../src/pages/auditoria.js" /* webpackChunkName: "component---src-pages-auditoria-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-consultoria-js": () => import("./../../../src/pages/consultoria.js" /* webpackChunkName: "component---src-pages-consultoria-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liberando-el-potencial-js": () => import("./../../../src/pages/liberando-el-potencial.js" /* webpackChunkName: "component---src-pages-liberando-el-potencial-js" */),
  "component---src-pages-mision-js": () => import("./../../../src/pages/mision.js" /* webpackChunkName: "component---src-pages-mision-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */)
}

